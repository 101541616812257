export const GET_MY_NEWS_REQUEST = 'GET_MY_NEWS_REQUEST';
export const GET_MY_NEWS_SUCCESS = 'GET_MY_NEWS_SUCCESS';
export const GET_MY_NEWS_FAILURE = 'GET_MY_NEWS_FAILURE';

export const GET_PRESENTLY_REQUEST = 'GET_PRESENTLY_REQUEST';
export const GET_PRESENTLY_SUCCESS = 'GET_PRESENTLY_SUCCESS';
export const GET_PRESENTLY_FAILURE = 'GET_PRESENTLY_FAILURE';

export const GET_RECENT_PAST_REQUEST = 'GET_RECENT_PAST_REQUEST';
export const GET_RECENT_PAST_SUCCESS = 'GET_RECENT_PAST_SUCCESS';
export const GET_RECENT_PAST_FAILURE = 'GET_RECENT_PAST_FAILURE';
