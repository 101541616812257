export const ADMIN_USER_DETAILS_REQUEST = 'ADMIN_USER_DETAILS_REQUEST';
export const ADMIN_USER_DETAILS_SUCCESS = 'ADMIN_USER_DETAILS_SUCCESS';
export const ADMIN_USER_DETAILS_FAILURE = 'ADMIN_USER_DETAILS_FAILURE';
export const ADMIN_USER_DETAILS_RESET = 'ADMIN_USER_DETAILS_RESET';

export const ADMIN_USER_DELETE_REQUEST = 'ADMIN_USER_DELETE_REQUEST';
export const ADMIN_USER_DELETE_SUCCESS = 'ADMIN_USER_DELETE_SUCCESS';
export const ADMIN_USER_DELETE_FAILURE = 'ADMIN_USER_DELETE_FAILURE';

export const ADMIN_GET_CONTACT_EMAIL_REQUEST =
  'ADMIN_GET_CONTACT_EMAIL_REQUEST';
export const ADMIN_GET_CONTACT_EMAIL_SUCCESS =
  'ADMIN_GET_CONTACT_EMAIL_SUCCESS';
export const ADMIN_GET_CONTACT_EMAIL_FAILURE =
  'ADMIN_GET_CONTACT_EMAIL_FAILURE';
export const ADMIN_GET_CONTACT_EMAIL_RESET = 'ADMIN_GET_CONTACT_EMAIL_RESET';

export const ADMIN_GET_ALL_IP_ADDRESS_REQUEST =
  'ADMIN_GET_ALL_IP_ADDRESS_REQUEST';
export const ADMIN_GET_ALL_IP_ADDRESS_SUCCESS =
  'ADMIN_GET_ALL_IP_ADDRESS_SUCCESS';
export const ADMIN_GET_ALL_IP_ADDRESS_FAILURE =
  'ADMIN_GET_ALL_IP_ADDRESS_FAILURE';
export const ADMIN_GET_ALL_IP_ADDRESS_RESET = 'ADMIN_GET_ALL_IP_ADDRESS_RESET';

export const ADMIN_UPLOAD_FILE_REQUEST = 'ADMIN_UPLOAD_FILE_REQUEST';
export const ADMIN_UPLOAD_FILE_SUCCESS = 'ADMIN_UPLOAD_FILE_SUCCESS';
export const ADMIN_UPLOAD_FILE_FAILURE = 'ADMIN_UPLOAD_FILE_FAILURE';
